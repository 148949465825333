import { useState } from 'react';
import firebase from '../../../firebase/config';

const useFirebaseCallable = (functionName, options) => {
  const [data, setData] = useState(undefined);
  const [error, setError] = useState(undefined);
  const [isLoading, setLoading] = useState(false);
  const fn = firebase?.functions().httpsCallable(functionName);
  const main = _options => {
    setData(undefined);
    setError(undefined);
    setLoading(true);
    const promise = new Promise((resolve, reject) => {
      fn(_options || options)
        .then(res => {
          setLoading(false);
          const result = res?.data?.data || res?.data || res?.result;
          setData(result);
          resolve(result);
        })
        .catch(e => {
          setLoading(false);
          setError(e);
          reject(e);
        });
    });
    return promise;
  };
  return [
    main,
    {
      data,
      isLoading: isLoading,
      loading: isLoading,
      error,
    },
  ];
};

export default useFirebaseCallable;

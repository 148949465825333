import React, { useEffect, useState } from 'react';
import Text from '../../../../components/Text';
import Input from '../../../../components/Input';
import { useFormik } from 'formik';
import Button from '../../../../components/Button';
import useFirebaseCallable from '../../../../utils/hooks/members/useFirebaseCallable';
import Alert from '../../../../components/Alert';
import MembersLayout from '../../../../components/Members/Layout';
import Card from '../../../../components/Card';
import Container from '../../../../components/Container';
import { Link, navigate } from 'gatsby';
import { useLocation } from '@reach/router';
import qs from 'qs';
import SEO from '../../../../components/seo';
import useBbshMember from '../../../../utils/hooks/members/useBbshMember';
import Spinner from '../../../../components/Spinner';

const ValidateAccount = () => {
  const location = useLocation();
  const [didTry, setDidTry] = useState(false);
  const { inviteCode } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const [validateUser, { loading, error, data }] = useFirebaseCallable(
    'validateMember',
  );
  const { member, isLoading } = useBbshMember();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: { inviteCode },
    onSubmit: values => {
      setDidTry(true);
      validateUser(values);
    },
  });
  useEffect(() => {
    if (data) {
      setTimeout(() => {
        navigate('/members');
      }, 5000);
    }
  }, [data]);
  return (
    <MembersLayout title="Validate account">
      <SEO title="Validate account" />
      <Container maxWidth={640} py={[3, null, 4]}>
        {isLoading ? (
          <Spinner mx="auto" display="block" />
        ) : member?.isValid && didTry === false ? (
          <Alert type="warning">
            Your account is already validated,{' '}
            <Link to="/members">click here</Link> to go to the portal.
            <br />
            If you{`'`}re trying to create a new one, please logout and try
            again with a different email address.
          </Alert>
        ) : (
          <Card p={4}>
            <form onSubmit={formik.handleSubmit}>
              <Text mb={3}>
                Please enter the invitation code that you received via email in
                order to validate your account
              </Text>
              <Input
                inline
                fontSize={3}
                name="inviteCode"
                mb={3}
                width={1}
                value={formik.values.inviteCode}
                onChange={formik.handleChange}
                label="Invitation code"
                required
              />
              <Button
                width={[1, null, 'auto']}
                type="submit"
                disabled={loading}
              >
                {loading ? 'Validating...' : 'Validate'}
              </Button>
              {error && (
                <Alert mt={3} type="danger">
                  {error.message}
                </Alert>
              )}
              {data && (
                <>
                  <Alert my={3} type="success">
                    Your account has been validated!
                  </Alert>
                  <Text>Redirecting you in a few moments...</Text>
                </>
              )}
            </form>
          </Card>
        )}
      </Container>
    </MembersLayout>
  );
};

export default ValidateAccount;
